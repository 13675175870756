import React, { Component } from "react";
import { JobOfferApi } from "../../services/JobOfferApi";
import JobOfferMain from "../components/jobs/JobOfferMain";
import JobOfferBasicInfo from "../components/jobs/JobOfferBasicInfo";

class JobOfferDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobOffer: null,
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const response = await JobOfferApi.getJobOfferById(Number(id));
    this.setState({ jobOffer: response });
  }

  render() {
    const { jobOffer } = this.state;

    return (
      <div className="main-container p-2" style={{ marginTop: 120 }}>
        <a
          href="/job-offersList"
          className="btn fl-btn-primary px-3"
          role="button"
        >
          <i className="bi bi-arrow-left-short me-2 fs-5"></i>
          Regresar
        </a>
        <div className="row mb-5 mt-32">
          {jobOffer ? (
            <>
              <div className="col-md-9" style={{ paddingRight: "30px" }}>
                <JobOfferMain jobOffer={jobOffer} />
              </div>
              <div className="col-md-3 d-sm-block">
                <JobOfferBasicInfo jobOffer={jobOffer} />
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    );
  }
}

// Usamos `useParams` para acceder a los parámetros de la URL
export default JobOfferDetail;
