import React, { Component } from "react";
import maintenance from "../imagenes/maintenance.png";

class Maintenance extends Component {
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light text-center">
        <div className="card shadow-lg border-0" style={{ maxWidth: "600px" }}>
          <img
            src={maintenance}
            alt="Mantenimiento"
            className="card-img-top img-fluid rounded-top"
            style={{ objectFit: "cover", maxWidth: "300px", margin: "0 auto" }}
          />
          <div className="card-body">
            <h1 className="text-primary mb-3">Plataforma en Mantenimiento</h1>
            <p className="lead text-muted">
              ¡Estamos trabajando para ti! Nuestra{" "}
              <strong>Plataforma de Educación Financiera</strong> está
              actualmente en mantenimiento para implementar mejoras y ofrecerte
              una experiencia más completa.
            </p>
            <p className="text-muted">
              El mantenimiento estará disponible hasta el{" "}
              <strong>15 de enero de 2025</strong>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Maintenance;
