import React from "react";
import apiVisitas from "../../services/visitasApi";

class AhorroInclusion extends React.Component {
  agregarVisitaAhorroInclusion = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Ahorro Inclusión" });
    } catch (error) {
      console.log("error al crear visíta " + error);
    }
  };

  componentDidMount() {
    this.agregarVisitaAhorroInclusion();
  }

  render() {
    return (
      <React.Fragment>
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>Ahorro Inclusión</h1>
                <h2 className="texto-justificado">
                  Ahorro Inclusión es la cuenta de ahorro ideal para personas
                  mayores de edad, mujeres embarazadas y personas con
                  discapacidad que reciben el bono del MIES. Permite realizar
                  retiros del bono y fomenta el ahorro y la planificación
                  financiera, brindándote herramientas para mejorar tus
                  habilidades financieras.
                </h2>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                {/* <img
                  src={AhorroJuniorImg}
                  className="image-cuenta-ahorros-junior rounded "
                  alt=""
                /> */}
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br /> <br />
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Monto de apertura:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    El monto para la apertura de la cuenta es de USD $20.00
                  </h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Requisitos:
                    </h5>
                  </div>
                  <h5 className="ps-4">- Mayores de 18 años</h5>
                  <h5 className="ps-4">- Copia a color de cédula</h5>
                  <h5 className="ps-4">
                    - Copia de la última planilla de cualquier servicio básico
                  </h5>
                  <h5 className="ps-4">- Correo Electrónico</h5>
                  <br />
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-xl-12 col-md-6 align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="alert alert-primary" role="alert">
                    <h5 className="texto-justificado mb-0 fw-bold">
                      Beneficios:
                    </h5>
                  </div>
                  <h5 className="ps-4">
                    - Peluquería, Odontología y Medicina General - (Fondo
                    Mortuorio) previo débito de $10.00
                  </h5>
                  <h5 className="ps-4">- Tarjeta de Débito</h5>
                  <h5 className="ps-4">- Acceso a la Banca web y móvil </h5>
                  <h5 className="ps-4">- Participa en sorteos trimestrales</h5>
                  <br />
                </div>
              </div>
              <br />
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default AhorroInclusion;
