import React from "react";
import AhorroDualImg from "../../imagenes/ahorrojr.png";
import apiVisitas from "../../services/visitasApi";

class AhorroDual extends React.Component {

    agregarVisitaAhorroDual = async () => {
        try {
            await apiVisitas.visitas.create({ tipo: "Ahorro Junior" });
        } catch (error) {
            console.log("error al crear visíta " + error);
        }
    }

    componentDidMount() {
        this.agregarVisitaAhorroDual();
    }

    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Ahorro Dual</h1>
                    <h2>
                      Es un producto de ahorro gestionado por dos personas, que
                      permite realizar depósitos y retiros, brindando
                      flexibilidad y seguridad en la administración compartida
                      del capital.
                    </h2>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    {/* <img
                      src={AhorroDualImg}
                      className="image-cuenta-ahorros-junior rounded "
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Monto de apertura:
                        </h5>
                      </div>
                      <h5 className="ps-4">
                        El monto requerido para la apertura de la cuenta es de
                        USD $0.00.
                      </h5>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Tasa de Interés:
                        </h5>
                      </div>
                      <h5 className="ps-4">
                        Las tasas de interés más altas del mercado.
                      </h5>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div
                      className="col-xl-12 col-md-6 align-items-stretch"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="alert alert-primary" role="alert">
                        <h5 className="texto-justificado mb-0 fw-bold">
                          Requisitos:
                        </h5>
                      </div>
                      <h5 className="ps-4">
                        - Personas mayores de 18 años.
                      </h5>
                      <h5 className="ps-4">
                        - Copia a color de cédula de las personas.
                      </h5>
                      <h5 className="ps-4">
                        - Copia de la última planilla de cualquier servicio
                        básico.
                      </h5>
                      <h5 className="ps-4">
                        - Acta de Nombramiento de la Directiva (no aplica en
                        el tipo de grupo indistinta).
                      </h5>
                      <h5 className="ps-4">- Correo Electrónico.</h5>
                      <br />
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default AhorroDual;