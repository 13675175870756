import React from "react";
import HorariosPng from "../../imagenes/horarios.png";

class Horarios extends React.Component {
    render() {
        return (
          <React.Fragment>
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h1>Horarios de Atención</h1>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 hero-img"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <img
                      src={HorariosPng}
                      className="img-fluid animated rounded "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <main id="main">
              <section id="services" className="services section-bg">
                <br /> <br />
                <div className="container" data-aos="fade-up">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-primary">
                        <th scope="col" className="text-center">
                          Agencias
                        </th>
                        <th scope="col" className="text-center">
                          Lunes a Viernes
                        </th>
                        <th scope="col" className="text-center">
                          Sábado a Domingo
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">La Maná Matriz</td>
                        <td className="text-center">08:00 – 17:00</td>
                        <td className="text-center">09:00 – 13:00</td>
                      </tr>
                      <tr>
                        <td className="text-center">Buena Fe</td>
                        <td className="text-center">08:00 – 16:00</td>
                        <td className="text-center">09:00 – 13:00</td>
                      </tr>
                      <tr>
                        <td className="text-center">Balzar</td>
                        <td className="text-center">08:00 – 16:00</td>
                        <td className="text-center">-</td>
                      </tr>
                      <tr>
                        <td className="text-center">El Empalme</td>
                        <td className="text-center">08:00 – 16:00</td>
                        <td className="text-center">-</td>
                      </tr>
                      <tr>
                        <td className="text-center">Paraíso la 14</td>
                        <td className="text-center">08:00 – 16:00</td>
                        <td className="text-center">09:00 – 13:00</td>
                      </tr>
                      <tr>
                        <td className="text-center">El Carmen – Manabí</td>
                        <td className="text-center">08:00 – 16:00</td>
                        <td className="text-center">-</td>
                      </tr>
                      <tr>
                        <td className="text-center">Pedernales</td>
                        <td className="text-center">08:00 – 16:00</td>
                        <td className="text-center">-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
              </section>
            </main>
          </React.Fragment>
        );
    }
}

export default Horarios;