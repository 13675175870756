import React, { Component } from "react";

class JobOfferSection extends Component {
  render() {
    const { title, children } = this.props;

    return (
      <div className="gap-2">
        <span className="text-lg fw-bold text-color-primary">{title}</span>
        {children}
      </div>
    );
  }
}

export default JobOfferSection;
