import React, { Component } from "react";
import CardComponent from "../../components/noticias/CardComponent.jsx";
import { fetchApiNodeNoticies } from "../../../services/noticiasApi.js";
import EmptyResource from "../../../imagenes/noticias/svg/Empty-resource.svg";
import "../../../styles/Notice.css";
import EmptyImageComponent from "../../components/noticias/EmptyImageComponent.jsx";

class Noticia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDataNotices: [],
      listCategory: [],
      selectedCategoryId: null, // Inicialmente, no hay una categoría seleccionada
      hasLoadedNotices: false, // Estado para controlar la carga de noticias
    };
  }

  componentDidMount() {
    this.loadCategories(); // Cargar categorías al montar el componente
  }

  componentDidUpdate(prevProps, prevState) {
    // Solo cargar noticias si la categoría seleccionada cambia y no se han cargado aún
    if (
      prevState.selectedCategoryId !== this.state.selectedCategoryId &&
      this.state.selectedCategoryId !== null &&
      !this.state.hasLoadedNotices
    ) {
      this.loadNoticesByCategory();
    }
  }

  loadCategories = async () => {
    try {
      const fetchData = await fetchApiNodeNoticies(
        "GET",
        "list-categories-active"
      );
      const categories = fetchData.data || [];

      this.setState({ listCategory: categories });
      // Establecer la primera categoría como seleccionada si hay categorías disponibles
      if (categories.length > 0) {
        const defaultCategoryId = categories[0].id_category;
        this.setState({ selectedCategoryId: defaultCategoryId });
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      this.setState({ listCategory: [], selectedCategoryId: null });
    }
  };

  loadNoticesByCategory = async () => {
    const { selectedCategoryId } = this.state;
    if (selectedCategoryId) {
      try {
        const params = { id_category: selectedCategoryId.toString() };
        const noticies = await fetchApiNodeNoticies(
          "POST",
          "list-noticies-active",
          params
        );
        this.setState({
          listDataNotices: noticies.data || [],
          hasLoadedNotices: true,
        });
      } catch (error) {
        console.error("Error fetching notices:", error);
        this.setState({ listDataNotices: [], hasLoadedNotices: true });
      }
    }
  };

  handleClickidCategory = (idCategory) => {
    this.setState({ selectedCategoryId: idCategory, hasLoadedNotices: false }); // Resetear la carga de noticias
  };

  handleNameCategory = (idCategory) => {
    const { listCategory } = this.state;
    const nameCategory = listCategory.find(
      (item) => item.id_category === idCategory
    );
    return nameCategory?.name;
  };

  render() {
    const { listDataNotices, listCategory, selectedCategoryId } = this.state;

    return (
      <>
        <section
          id="hero"
          className="d-flex align-items-center"
          style={{ height: "25vh" }}
        >
          <div className="container mt-4">
            <div className="row">
              <div
                className="col-lg-2 d-flex flex-column justify-content-center text-center pt-lg-0"
                data-aos="fade-up"
              >
                <h1>Noticias</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row py-4">
            <div className="col-lg-3 col-sm-12">
              <div className="container-category" data-aos="fade-up">
                <div className="title-category mb-2">
                  <span>Categorías:</span>
                </div>
                <div className="list-group p-2">
                  {listCategory.length > 0 ? (
                    listCategory.map((item) => (
                      <button
                        key={item.id_category}
                        style={{
                          borderColor: "gray",
                          backgroundColor:
                            selectedCategoryId === item.id_category
                              ? "#77B735"
                              : "",
                        }}
                        className={`list-group-item list-group-item-action ${
                          selectedCategoryId === item.id_category
                            ? "active"
                            : ""
                        }`}
                        onClick={() =>
                          this.handleClickidCategory(item.id_category)
                        }
                      >
                        {item.name}
                      </button>
                    ))
                  ) : (
                    <span>No hay datos</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-12" data-aos="fade-up">
              <div className="row d-flex justify-content-around align-items-stretch">
                {listDataNotices.length === 0 ? (
                  <EmptyImageComponent
                    srcImage={EmptyResource}
                    description={"No se tiene recursos"}
                  />
                ) : (
                  listDataNotices.map((data) => (
                    <CardComponent
                      key={data.id_notice}
                      id_notice={data.id_notice}
                      idCateg={selectedCategoryId}
                      date_time={data.date_time}
                      nameCateg={this.handleNameCategory(selectedCategoryId)}
                      title={data.title}
                      img_card={data.img_card}
                      description={data.description}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Noticia;
