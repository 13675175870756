import React, { Component } from "react";
import { transformDate } from "../../../utils/transform-date.util";

class JobOfferBasicInfo extends Component {
  render() {
    const { jobOffer } = this.props;

    return (
      <div className="d-flex flex-column gap-3">
        <span
          className="text-lg fw-bold text-color-primary"
          style={{ lineHeight: "20px" }}
        >
          Información del empleo
        </span>
        <div className="d-flex flex-column">
          <span className="text-opacity-90 text-sm">Fecha de publicación</span>
          <span className="text-sm">{transformDate(jobOffer.createdAt)}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-opacity-90 text-sm">Tipo de empleo</span>
          <span className="text-sm">Tiempo Completo</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-opacity-90 text-sm">Departamento</span>
          <span className="text-sm">{jobOffer.departament}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-opacity-90 text-sm">Nivel de Educación</span>
          <span className="text-sm">{jobOffer.levelEducation}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-opacity-90 text-sm">Experiencia laboral</span>
          <span className="text-sm">{jobOffer.timeExperience}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="text-opacity-90 text-sm">Agencia</span>
          <span className="text-sm">{jobOffer.branch}</span>
        </div>
      </div>
    );
  }
}

export default JobOfferBasicInfo;
