import React, { Component } from "react";
import JobOfferSection from "./JobOfferSection";

class JobOfferMain extends Component {
  render() {
    const { jobOffer } = this.props;

    return (
      <div className="d-flex flex-column my-4">
        <div className="d-flex flex-row gap-3 flex-center align-items-center">
          <span className="text-2xl fw-bolder mb-3">{jobOffer?.title}</span>
        </div>

        {/* <img src={"/images/1.jpg"} alt="Logo Futuro Lamanense" height={500} /> */}

        <JobOfferSection title="Descripción">
          <p style={{ textAlign: "justify", lineHeight: "1.75" }}>
            {jobOffer.description}
          </p>
        </JobOfferSection>

        <JobOfferSection title="Requisitos">
          <ul>
            {jobOffer.requirements.map((req, index) => (
              <li
                style={{ textAlign: "justify", lineHeight: "1.75" }}
                key={index}
              >
                {req}
              </li>
            ))}
          </ul>
        </JobOfferSection>

        <JobOfferSection title="Funciones">
          <p style={{ textAlign: "justify", lineHeight: "1.75" }}>
            {jobOffer.descriptionFunctions}
          </p>
        </JobOfferSection>

        <JobOfferSection title="Beneficios">
          <ul style={{ textAlign: "justify", lineHeight: "1.75" }}>
            <li>Salario competitivo.</li>
            <li>Bonificaciones por desempeño.</li>
            <li>Buen ambiente laboral.</li>
          </ul>
        </JobOfferSection>

        {jobOffer.isActive ? (
          <div className="d-flex flex-row justify-content-center">
            <a
              href="mailto:talentohumano@futurolamanense.fin.ec"
              className="btn fl-btn-primary"
              style={{ width: "300px" }}
              role="button"
            >
              Estoy interesado/a
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default JobOfferMain;
