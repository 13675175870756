import React from "react";

class QuienesSomos extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column  pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <div className="text-center">
                                    <h1>¿QUIENES SOMOS?</h1>
                                </div>

                                <div className="texto-justificado pt-5">
                                    <h2>
                                        Somos una Cooperativa bajo la supervisión de la Superintendencia de Economía Popular y Solidaria. Nuestra gestión sostenible impulsa un equilibro social, ambiental y económico de nuestros grupos de interés: socios, organismos de control, directivos, gobiernos locales, talento humano, proveedores, medios de comunicación, comunidad y medio ambiente. Cada uno de estos actores nos ha otorgado la licencia para operar, gracias a su confianza y respaldo hemos alcanzado varios reconocimientos que hacen de la Cooperativa de Ahorro y Crédito Futuro Lamanense una entidad eficiente y segura.
                                    </h2>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services">
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Filosofía Institucional Cooperativa Futuro Lamanense</h2>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <h4><a href="">MISIÓN</a></h4>
                                        <h5 className="texto-justificado">
                                            Ofrecemos servicios financieros ágiles y eficientes, fundamentados en los principios cooperativistas, contribuimos en el crecimiento progresivo de los sectores productivos y comerciales, así como en sus zonas de influencia, con el fin de mejorar las condiciones de vida para nuestros socios / clientes.
                                        </h5>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                    <div className="icon-box">
                                        <h4><a href="">VISIÓN</a></h4>
                                        <h5 className="texto-justificado">
                                            Para el 2027, ser un cooperativa confiable y sostenible, reconocida en el Segmento II de la SEPS por brindar servicios financieros inclusivos que promuevan el desarrollo y el bienestar en la región Centro-Costa.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /> <br />
                    </section>

                    <section id="services" className="services section-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>NUESTRO OBJETIVOS ES:</h2>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <ul>
                                            <li>
                                                <h5 className="texto-justificado mb-3">
                                                    Fomentar el ahorro en la comunidad a través de las operaciones de captación de recursos en depósitos de ahorros a la vista a plazo fijo y en las diferentes formas de captación permitidas por la legislación vigente, el estatuto y los reglamentos.
                                                </h5>
                                            </li>
                                            <li>
                                                <h5 className="texto-justificado mb-3">
                                                    Fomentar los principios de autoayuda y autogestión a través de la prestación de servicios de crédito, desarrollando sus actividades de acuerdo a las diferentes clases y modalidades y conforme lo establezca el reglamento de crédito.
                                                </h5>
                                            </li>
                                            <li>
                                                <h5 className="texto-justificado mb-3">
                                                    Alcanzar niveles de autoestima financiera.
                                                </h5>
                                            </li>
                                            <li>
                                                <h5 className="texto-justificado mb-3">
                                                    Expandir los servicios y productos institucionales de acuerdo a la planificación y presupuesto establecido.
                                                </h5>
                                            </li>
                                            <li>
                                                <h5 className="texto-justificado">
                                                    Contar con recursos humanos, financieros, materiales y tecnológicos adecuadamente administrados.
                                                </h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /> <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default QuienesSomos;